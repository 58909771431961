import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  // ApolloLink,
} from "@apollo/client";
import { GQL_URL } from "../../config/global";
import {
  getToken,
  hasToken,
} from "../TokenHelper";

const httpLink = new HttpLink({
  uri: GQL_URL,
  credentials: "include",
});

const AddToken = new ApolloLink(
  (operation, forward) => {
    if (hasToken()) {
      console.log(`hasToken ${getToken()}`);
      operation.setContext(
        ({ headers = {} }) => ({
          headers: {
            ...headers,
            Authorization: `${getToken()}`,
          },
        })
      );
    }
    return forward(operation);
  }
);

export const client = new ApolloClient({
  link: from([
    // RefreshToken,
    AddToken,
    httpLink as any,
  ]),
  credentials: "include",
  cache: new InMemoryCache(),
});
