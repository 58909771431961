import React, { useCallback, useState } from "react";
import {
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  useRecolorActions,
  useRecolorPreview,
  useSaveRecolorAssets,
} from "../../services/RecolorLayer/RecolorLayer";
import getRedirectUrl, {
  getRedirectProjectUrl,
  getRedirectUrlWithDefaultProduct,
} from "../../utils/getRedirectUrl";
import CancelModal from "../CancelModal/CancelModal";
import Button from "../DS/Button";

import ButtonWithIcon from "../DS/ButtonWithIcon";
import HidePreviewIcon from "../Icons/HidePreviewIcon";
import PreviewIcon from "../Icons/PreviewIcon";
import RedoIcon from "../Icons/RedoIcon";
import ResetIcon from "../Icons/ResetIcon";
import UndoIcon from "../Icons/UndoIcon";
import SaveModal from "../SaveModal/SaveModal";
import SplashLoader from "../SplashLoader/SplashLoader";

const ActionBar = () => {
  const {
    onRedo,
    onUndo,
    canRedo,
    canUndo,
    canStartOver,
    onStartOver,
  } = useRecolorActions();
  const { isPreviewMode, onPreviewModeChange } =
    useRecolorPreview();
  const { isSaving, onSave, designName, isNew } =
    useSaveRecolorAssets();
  const { productId } = useParams();
  const [searchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const onPressYes = useCallback(() => {
    const isFromProject =
      searchParams.get("from") === "project";
    const cancelId =
      searchParams.get("inventcolorid") ||
      searchParams.get("cancel_id");
    const projectTabId =
      searchParams.get("projectTabId") || "";

    (window as any).location = isFromProject
      ? getRedirectProjectUrl(projectTabId, productId || "")
      : getRedirectUrlWithDefaultProduct(
          productId || "",
          cancelId || "99"
        );
  }, [productId, searchParams]);
  const onSaveCB = useCallback(
    (name: string, id: string) => {
      onSave(name, id);
    },
    [onSave]
  );
  return (
    <>
      <div className="buttonContainer">
        <Button
          className={isPreviewMode ? "previewMode" : ""}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Cancel
        </Button>
        <ButtonWithIcon
          tooltip="Undo"
          onClick={onUndo}
          disabled={!canUndo}
          className={isPreviewMode ? "previewMode" : ""}
        >
          <UndoIcon />
        </ButtonWithIcon>

        <ButtonWithIcon
          disabled={!canRedo}
          tooltip="Redo"
          onClick={onRedo}
          className={isPreviewMode ? "previewMode" : ""}
        >
          <RedoIcon />
        </ButtonWithIcon>
        <ButtonWithIcon
          tooltip="Start Over"
          onClick={onStartOver}
          disabled={!canStartOver}
          className={isPreviewMode ? "previewMode" : ""}
        >
          <ResetIcon />
        </ButtonWithIcon>
        <ButtonWithIcon
          tooltip={
            isPreviewMode ? "Show Controls" : "Preview"
          }
          onClick={() => {
            onPreviewModeChange(!isPreviewMode);
          }}
        >
          {!isPreviewMode ? (
            <HidePreviewIcon />
          ) : (
            <PreviewIcon />
          )}
        </ButtonWithIcon>
        <Button
          className={isPreviewMode ? "previewMode" : ""}
          disabled={isSaving}
          onClick={() => {
            if (designName.length) onSave(designName);
            else setShowSaveModal(true);
          }}
        >
          {isSaving
            ? "Saving.."
            : isNew
            ? "Save To Projects"
            : "Save Changes"}
        </Button>
      </div>
      {isSaving ? <SplashLoader text="Saving..." /> : null}
      {showModal ? (
        <CancelModal
          text={
            <>
              Are you sure you want to exit and <br />{" "}
              discard this recoloring?
            </>
          }
          onCancel={() => {
            setShowModal(false);
          }}
          onYes={onPressYes}
        />
      ) : null}
      {showSaveModal ? (
        <SaveModal
          onCancel={() => {
            setShowSaveModal(false);
          }}
          onYes={(newName, id) => {
            onSaveCB(newName, id);
            setShowSaveModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ActionBar;
