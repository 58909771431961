import jwtDecode from "jwt-decode";
export const hasToken = () => {
  const _search = new URLSearchParams(
    window.location.search
  );

  return (
    _search.has("token") &&
    (_search.get("token") || "").length > 0
  );
};

export const getToken = () => {
  const _search = new URLSearchParams(
    window.location.search
  );

  return _search.get("token") || "";
};

export const getUserId = () => {
  if (!hasToken()) {
    return "";
  }

  const _token = getToken();
  const _userId = jwtDecode<{ data: { user_id: string } }>(
    _token
  );

  return _userId?.data?.user_id || "";
};
