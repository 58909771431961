import { IS_DEV } from "../config/global";

const getRedirectUrl = (id: string) => {
  if (IS_DEV) {
    return `https://newstage.rmcoco.com/shop/product/${id}/99`;
  } else {
    return `https://rmcoco.com/shop/product/${id}/99`;
  }
};
export const getRedirectUrlWithDefaultProduct = (
  id: string,
  color: string
) => {
  if (IS_DEV) {
    return `https://newstage.rmcoco.com/shop/product/${id}/${color}`;
  } else {
    return `https://rmcoco.com/shop/product/${id}/${color}`;
  }
};

export const getRedirectProjectUrl = (
  id: string,
  productId: string
) => {
  if (IS_DEV) {
    return `https://newstage.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  } else {
    return `https://rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  }
};
export const getRedirectProjectWithClientUrl = (
  id: string,
  productId: string
) => {
  if (IS_DEV) {
    return `https://newstage.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  } else {
    return `https://rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  }
};

export default getRedirectUrl;
